
.lnb--all-white ::v-deep {
    line-height: 1.35;
    padding: 32px 0;
    background-color: transparent;
    > li {
        > a {
            font-weight: 400;
            color: var(--v-grey-lighten1);
            padding: 8px;
            &:hover {
                color: var(--v-primary-base);
            }
        }
    }
}
