
// footer-slider
.footer-slider {
    position: relative;
    width: 100%;
    padding: 14px 0;

    &__inner {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 48px;
        padding: 2px 10px;
        background-color: #fff;
    }
    &__img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        border-radius: 0 !important;
    }

    .swiper-container,
    .swiper-control {
        max-width: calc(var(--container) + ((var(--container-gutter) + 44px) * 2));
    }
    .swiper-container {
        padding: 0 var(--container-gutter);
        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 0;
            z-index: 2;
            display: block;
            width: calc(var(--container-gutter) + 44px);
            height: 100%;
            background-color: var(--v-grey-base);
        }
        &::before {
            left: -2px;
        }
        &::after {
            right: -2px;
        }
    }
    .swiper-control {
        z-index: 3;
        height: auto;
    }
    .swiper-container::before,
    .swiper-container::after,
    .swiper-control {
        display: none;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .footer-slider {
        padding: 20px 0;
        &__inner {
            padding: 2px 14px;
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    .footer-slider {
        .swiper-container {
            padding: 0 calc(var(--container-gutter) + 44px);
        }
        .swiper-container::before,
        .swiper-container::after,
        .swiper-control {
            display: block;
        }
    }
}
