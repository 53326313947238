
.footer-head--basic {
    color: #fff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    padding: 14px 0;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .footer-head--basic {
        padding: 16px 0;
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
