
// gnb
.gnb {
    display: none;
}
@media (min-width: 1200px) {
    .gnb {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        width: auto;
        &__item {
            position: relative;
        }
        &__link {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            min-height: var(--header-body-height);
            font-size: var(--gnb-font-size);
            font-weight: var(--gnb-font-weight);
            color: var(--v-grey-darken4);
            padding: var(--gnb-padding-y) var(--gnb-padding-x);
            -webkit-transition: all ease-out 0.2s;
            -ms-transition: all ease-out 0.2s;
            transition: all ease-out 0.2s;
        }
        h2 {
            font-size: inherit;
            font-weight: inherit;
            color: inherit;
        }

        // light
        &--light {
            ::v-deep {
                .gnb__link {
                    color: #fff;
                }
            }
        }
    }
}

// gnb-mo
.gnb-mo {
    h2 {
        font-size: inherit;
        font-weight: inherit;
        color: inherit;
    }
    &__item {
        position: relative;
        &.open {
            .gnb-mo__sub {
                height: auto;
                opacity: 1;
                visibility: visible;
                padding: 8px 0;
            }
        }
    }
    &__link {
        position: relative;
        display: flex;
        align-items: center;
        height: 54px;
        font-size: 1.4rem;
        padding: 0 var(--container-gutter);
    }
    &__link--toggle {
        padding-right: calc(var(--container-gutter) + 30px);
    }
    &__arrow {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        width: calc(var(--container-gutter) + 30px) !important;
        height: 54px !important;
        border-radius: 0 !important;
    }
    &__sub {
        height: 0;
        overflow: hidden;
        opacity: 0;
        visibility: hidden;
        transition: all ease-out 0.2s;
        .gnb-mo__sub {
            padding: 0 0 0 var(--container-gutter) !important;
        }
    }
    &__sub-link {
        display: flex;
        align-items: center;
        font-size: 1.4rem;
        padding: 6px var(--container-gutter);
    }
}

// sitemap-menu
.sitemap-menu {
    h2 {
        font-size: inherit;
        font-weight: inherit;
        color: inherit;
    }
    &__sub-item {
        .sitemap-menu__sub {
            height: 0;
            margin: 0;
            overflow: hidden;
            opacity: 0;
            visibility: hidden;
            transition: all ease-out 0.2s;
        }

        &.open {
            .sitemap-menu {
                &__sub {
                    height: auto;
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}
