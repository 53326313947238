
// main-visual
.main-visual {
    width: 100%;
    &__inner {
        position: relative;
        display: block;
        // max-width: 1920px;
        width: 100%;
        padding-top: calc(2 / 3 * 100%);
        margin: auto;
    }
    &__content {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: calc(var(--container) + var(--container-gutter) * 2);
        padding: 0 var(--container-gutter) 10px;
        color: #fff;
        text-align: center;
    }
    &__img {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: -1;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-color: rgba(0, 0, 0, 0.25);
    }
    .theme--light.v-card {
        background-color: transparent;
    }
}
@media (min-width: 576px) {
    .main-visual {
        &__inner {
            padding-top: calc(2 / 4 * 100%);
        }
    }
}
@media (min-width: 768px) {
    .main-visual {
        &__content {
            padding-bottom: 0;
        }
    }
}
@media (min-width: 1024px) {
    .main-visual {
        &__inner {
            padding-top: 0;
            height: 582px;
        }
    }
}
