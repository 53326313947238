
.col-true {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}
@media (max-width: 768px) {
    .txt-items {
        --grid-gutter-xxs: 3px;
    }
    .txt-item {
        &__col-tit {
            padding-bottom: 2px !important;
        }
        &__col-txt {
            &:not(.col-true) {
                padding-top: 0 !important;
            }
        }
    }
}
