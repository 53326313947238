
// Size
.icon--size {
    &-xx-small {
        height: 8px;
    }
    &-x-small {
        height: 10px;
    }
    &-small {
        height: 12px;
    }
    &-default {
        height: 14px;
    }
    &-large {
        height: 16px;
    }
    &-x-large {
        height: 18px;
    }
    &-xx-large {
        height: 20px;
    }
}
