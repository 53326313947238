
.header-body--basic {
    background-color: rgba(255, 255, 255, 1);
    border-bottom: 1px solid var(--v-grey-lighten5);
    -webkit-transition: all ease-out 0.6s;
    -ms-transition: all ease-out 0.6s;
    transition: all ease-out 0.6s;
}
.scroll-active {
    .header-body--basic {
        background-color: rgba(255, 255, 255, 0.8);
    }
}
