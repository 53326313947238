
.tit--icon {
    --tit-icon-width: 32px;
    --tit-icon-height: 16px;
    --tit-icon-margin-bottom: 16px;
    position: relative;
    padding-top: calc(var(--tit-icon-height) + var(--tit-icon-margin-bottom));
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        -webkit-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        display: block;
        width: var(--tit-icon-width);
        height: var(--tit-icon-height);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        background-image: url("/images/icon/icon-infinite.png");
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .tit--icon {
        --tit-icon-width: 48px;
        --tit-icon-height: 24px;
        --tit-icon-margin-bottom: 24px;
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
