
.card-tertiary {
    background-color: transparent;
    &.v-card--link:hover {
        ::v-deep {
            .v-divider {
                border-color: var(--v-primary-base) !important;
                -webkit-transition: all ease-out 0.5s;
                -ms-transition: all ease-out 0.5s;
                transition: all ease-out 0.5s;
            }
        }
    }
}
