
// Default
.tit-wrap {
    padding-bottom: var(--tit-wrap-padding-bottom);
    &--sm {
        padding-bottom: var(--tit-wrap-padding-bottom-sm);
    }
    &--lg {
        padding-bottom: var(--tit-wrap-padding-bottom-lg);
    }
    .tit + .txt {
        margin-top: 8px;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .tit-wrap {
        .tit + .txt {
            margin-top: 14px;
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
