
// main-notice-slider
.main-notice-slider {
    width: 100%;
    &__inner {
        position: relative;
        display: block;
        width: 100%;
        height: 240px;
        background-repeat: no-repeat;
        background-position: right bottom;
        background-size: 166px 72px;
        background-image: url(/images/main/infinite-bg.png);
    }
    .v-card.v-sheet--outlined {
        background-color: #fff !important;
    }

    .swiper-control {
        max-width: none;
        width: auto;
        height: auto;
        top: auto;
        left: auto;
        right: 16px;
        bottom: 16px;
        transform: none;
        .swiper-button-next,
        .swiper-button-prev {
            transform: none;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .main-notice-slider {
        &__inner {
            height: 288px;
        }

        .swiper-control {
            right: 26px;
            bottom: 26px;
        }
    }
}
@media (min-width: 1024px) {
    .main-notice-slider {
        .swiper-control {
            right: 40px;
            bottom: 40px;
        }
    }
}
