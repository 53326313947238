
.footer-body--basic {
    padding: 30px 0;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .footer-body--basic {
        padding: 48px 0;
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
